import { useHead, useJsonParse } from '#imports';

/**
 * Use seo composable.
 *
 * @param {Object} seo
 */
export default function useSeo(seo) {
  const metaTitleContainer = useJsonParse(seo.metaTitleContainer);
  const metaTagContainer = useJsonParse(seo.metaTagContainer);
  const metaLinkContainer = useJsonParse(seo.metaLinkContainer);
  const metaScriptContainer = useJsonParse(seo.metaScriptContainer);
  const metaJsonLdContainer = useJsonParse(seo.metaJsonLdContainer);

  // Format for Nuxt
  const title = metaTitleContainer?.title?.title;
  const meta = Object.values(metaTagContainer).filter((m) => m.content);
  const link = Object.values(metaLinkContainer).filter((m) => m.rel);
  const script = Object.values(metaScriptContainer).map((s) => ({ children: s.script }));
  const jsonLd = Object.values(metaJsonLdContainer).map((s) => ({ children: JSON.stringify(s), type: 'application/ld+json' }));
  const siteVars = { children: seo.metaSiteVarsContainer, type: 'application/ld+json' };

  // Use in head
  useHead({
    title,
    meta,
    link,
    script: script.concat(jsonLd).concat(siteVars),
  });
}
