/**
 * Use json parse composable.
 *
 * @param {Object} seo
 */
export default function useJsonParse(json) {
  try {
    return JSON.parse(json);
  } catch (e) {
    return {};
  }
}
